import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import "./global.css";
import "./satoshi.css";
import './index.css';

const muiTheme = createTheme({
  palette: {
    primary: { main: "rgba(255,255,255,1)", light: "rgba(28,74,122,1)" },
    mode: "light",
    text: { primary: "rgba(0,0,0,0.87)" },
  },
  typography: { 
    fontFamily: "Satoshi-Bold, sans-serif"
 },
});
const chakraTheme = extendTheme({
  styles: { global: { img: { maxWidth: "unset" } } },
  colors: {
    gray: {
      50: "#55a6dc",
      100: "#55a6dc",
      200: "#55a6dc",
      300: "#55a6dc",
      400: "#55a6dc",
      500: "#55a6dc",
      600: "#55a6dc",
      700: "#55a6dc",
      800: "#55a6dc",
      900: "#55a6dc",
    },
    weregrweg: {
      50: "#1a95ba",
      100: "#fff",
      200: "#fff",
      300: "#fff",
      400: "#fff",
      500: "#fff",
      600: "#fff",
      700: "#fff",
      800: "#fff",
      900: "#fff",
    },
  },
  fonts: { heading: "Satoshi-Black, sans-serif", body: "Satoshi-Black, sans-serif" },
});
const emotionCache = createCache({
  key: "emotion-cache",
  prepend: true,
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <CacheProvider value={emotionCache}>
          <ChakraProvider theme={chakraTheme}>
            <CssBaseline />
            <App />
          </ChakraProvider>
        </CacheProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
