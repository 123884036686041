// src/Home.js
import { FunctionComponent, useEffect, useState } from "react";
import { Button, useMediaQuery } from "@chakra-ui/react";
import "../index.css";

const Home: FunctionComponent = () => {
  const [isLaptop] = useMediaQuery("(max-width: 1600px)");
  const [isMobile] = useMediaQuery("(max-width: 1000px)");
  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  if (isMobile) {
    return (
      <div className="coming-soon">
        <br/ >
          <span>
          Mobile Not Supported Yet.
          </span>
      </div>
    );
  }

  if (isLaptop) {
    return (
<div
      className="relative w-full flex flex-col items-center justify-start text-left text-6xl text-white font-satoshi"
      style={{
        backgroundImage: 'url(/body1@3x.png)',
        backgroundRepeat: 'repeat-y',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100vw',
        height: '100%'
      }}
    >
      <div className="main-content self-stretch flex flex-col items-center justify-start">
        <div className="content flex flex-col pt-[89px] px-[114px] pb-[141px] items-center justify-start gap-[66px]">
          <div>
            <div className="w-[289.48px] shrink-0 flex flex-row items-center justify-between">
              <a
                className="[text-decoration:none] relative font-black text-[inherit] inline-block w-[179.2px] h-[42.61px] shrink-0"
                href="https://docs.meth.finance/"
                target="_blank"
              >
                Tokenomics
              </a>
              <a
                className="[text-decoration:none] relative font-black text-[inherit] inline-block w-[76.44px] h-[42.61px] shrink-0"
                href="https://docs.meth.finance/"
              >
                Docs
              </a>
            </div>
          </div>
          <div
  className="mega-meth-section self-stretch h-[567.68px] shrink-0 flex flex-col items-center justify-start [&.animate]:animate-[1s_ease_0s_1_normal_forwards_fade-in] text-center text-[100px]"
  data-animate-on-scroll
>
  <div className="relative font-black text-[100px]">MEGA METH</div>
  <div className="relative text-[32px] mt-[-3px]">
    Unlocking the latent potential of Ethereum.
  </div>
  <div className="w-[418.5px] flex flex-row p-2.5 box-border items-center justify-center mt-[-3px]">
    <img
      className="flex-1 relative max-w-full overflow-hidden h-[398.5px] object-cover"
      alt=""
      src="/center-image@2x.png"
    />
  </div>
</div>
        <Button
          variant="outline"
          w="179px"
          colorScheme="weregrweg"
          name="Staking"
          as="a"
          href="/staking"
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 1)', color: "black" }}
          bg="rgba(255, 255, 255, 0.5)"
        >
          Staking
        </Button>
        </div>
      </div>
    </div>
    );
  }

  return (
    <div
      className="relative w-full flex flex-col items-center justify-start text-left text-6xl text-white font-satoshi"
      style={{
        backgroundImage: 'url(/body1@3x.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh'
      }}
    >
      <div className="main-content self-stretch flex flex-col items-center justify-start">
        <div className="content flex flex-col pt-[89px] px-[114px] pb-[141px] items-center justify-start gap-[66px]">
          <div>
            <div className="w-[289.48px] shrink-0 flex flex-row items-center justify-between">
              <a
                className="[text-decoration:none] relative font-black text-[inherit] inline-block w-[179.2px] h-[42.61px] shrink-0"
                href="https://docs.meth.finance/"
                target="_blank"
              >
                Tokenomics
              </a>
              <a
                className="[text-decoration:none] relative font-black text-[inherit] inline-block w-[76.44px] h-[42.61px] shrink-0"
                href="https://docs.meth.finance/"
              >
                Docs
              </a>
            </div>
          </div>
          <div
  className="mega-meth-section self-stretch h-[567.68px] shrink-0 flex flex-col items-center justify-start [&.animate]:animate-[1s_ease_0s_1_normal_forwards_fade-in] text-center text-[100px]"
  data-animate-on-scroll
>
  <div className="relative font-black text-[100px]">MEGA METH</div>
  <div className="relative text-[32px] mt-[-3px]">
    Unlocking the latent potential of Ethereum.
  </div>
  <div className="w-[418.5px] flex flex-row p-2.5 box-border items-center justify-center mt-[-3px]">
    <img
      className="flex-1 relative max-w-full overflow-hidden h-[398.5px] object-cover"
      alt=""
      src="/center-image@2x.png"
    />
  </div>
</div>

        <Button
          variant="outline"
          w="179px"
          colorScheme="weregrweg"
          name="Staking"
          as="a"
          href="/staking"
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 1)', color: "black" }}
          bg="rgba(255, 255, 255, 0.5)"
        >
          Staking
        </Button>
        </div>
      </div>
    </div>
  );
};

export default Home;
