import * as React from 'react';
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi';
import { ethers, utils } from "ethers";
import { Button } from "@mui/material";
import useDebounce from './useDebounce';

export function ClaimETHMEGA() {
  const [payableAmount, setPayableAmount] = React.useState('0');
  const debouncedpayableAmount = useDebounce(payableAmount, 500);

  const { config } = usePrepareContractWrite({
    address: '0xE1f683D5C0544DfaD75e9275D889a6B4bA37657d',
    abi: [
      {
        name: 'getReward',
        type: 'function',
        stateMutability: 'nonpayable',
        inputs: [],
        outputs: [],
      },
    ],
    functionName: 'getReward',
    overrides: {
      gasLimit: ethers.BigNumber.from(250000)
    },
  });

  const { data, write } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    write?.();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label htmlFor="payableAmount" style={{ marginRight: '10px' }}>
          </label>
        <Button
          disabled={!write || isLoading}
          sx={{ width: 124.92630004882812, height: 50 }}
          variant="outlined"
          color="primary"
          type="submit"
        >
          {isLoading ? 'Claiming...' : 'Claim'}
        </Button>
        </div>
        {isSuccess && (
          <div>
            <h4 style ={{ fontSize: '12px', marginTop: '5px'}}>Claimed!</h4>
            <div>
              <h4 style ={{ fontSize: '12px', marginTop: '3px'}}><a href={`https://etherscan.io/tx/${data?.hash}`}>Etherscan</a></h4>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
