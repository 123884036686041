import { useAccount, useQuery } from "wagmi";
import { multicall } from "@wagmi/core";
import { MEGAPoolABI } from "../abi/MEGAPoolABI";
import { ETHPoolABI } from "../abi/ETHPoolABI";
import { LPPoolABI } from "../abi/LPPoolABI";

const contractAddress = "0xe5162E24DB355C1cc3195972AC3A0dE932CB9E44";
const contractAddressB = "0xfF42d0c6D8846E4DE785134106a1AD9246fBEb7D";
const contractAddressC = "0xE1f683D5C0544DfaD75e9275D889a6B4bA37657d";

async function getStakingInfoMEGA(userAddress: string | undefined) {
  try {
    if (!userAddress) throw new Error("Address Not Detected");
    const contract = {
      address: contractAddress as `0x${string}`,
      abi: MEGAPoolABI,
    };
    const contractB = {
      address: contractAddressB as `0x${string}`,
      abi: ETHPoolABI,
    };
    const contractC = {
      address: contractAddressC as `0x${string}`,
      abi: LPPoolABI,
    };

    const data = await multicall({
      contracts: [
        {
          ...contract,
          functionName: "earned",
          args: [userAddress],
        },
        {
          ...contract,
          functionName: "rewardRate",
          args: [],
        },
        {
          ...contract,
          functionName: "balanceOf",
          args: [userAddress],
        },
        {
          ...contractB,
          functionName: "earned",
          args: [userAddress],
        },
        {
          ...contractB,
          functionName: "rewardRate",
          args: [],
        },
        {
          ...contractB,
          functionName: "balanceOf",
          args: [userAddress],
        },
        {
          ...contractC,
          functionName: "earned",
          args: [userAddress],
        },
        {
          ...contractC,
          functionName: "rewardRate",
          args: [],
        },
        {
          ...contractC,
          functionName: "balanceOf",
          args: [userAddress],
        },
      ],
    });

    return data;
  } catch (e) {
    return null;
  }
}

export default function useGetStakingInfoMEGA() {
  const { address } = useAccount();
  return useQuery(["stakingInfo", address], () => getStakingInfoMEGA(address));
}