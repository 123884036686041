import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { ChainId, Token, WETH, Fetcher } from '@uniswap/sdk';
import BigNumber from "bignumber.js";

const TVL_QUERY_INTERVAL = 5000; // query TVL every 5 seconds
const contractAddress: string = "0xe5162E24DB355C1cc3195972AC3A0dE932CB9E44";
const MEGA_ABI = ['function balanceOf(address owner) view returns (uint256)', 'function decimals() view returns (uint8)'];
const MEGA_ADDRESS = '0x282D0D15682ACc34de0d4157cD07F35E5af325C7'

function MEGATVL() {
  const [tvl, setTVL] = useState<ethers.BigNumber | null>(null);

  useEffect(() => {
    let isMounted = true;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(contractAddress, ['function totalValueLocked() view returns (uint256)'], provider);
    const ethmega = new ethers.Contract(MEGA_ADDRESS, MEGA_ABI, provider);

    const queryTVL = async () => {
      try {
        const result = await ethmega.balanceOf(contractAddress);
        if (isMounted) {
          setTVL(result.sub(200000));
        }
      } catch (error) {
        console.error(error);
      }
    };    

    queryTVL();
    const intervalId = setInterval(queryTVL, TVL_QUERY_INTERVAL);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div>
      {tvl === null ? (
        <p>Loading TVL...</p>
      ) : (
      <p>${((Number.parseFloat(ethers.utils.formatUnits(tvl, 18)) - 160000) * 0.023).toFixed(2)}</p>

      )}
    </div>
  );  
}

export default MEGATVL;
