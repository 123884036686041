import BigNumber from 'bignumber.js'
import { useBalance, useAccount } from 'wagmi'

function Balance() {
const { address, isConnecting, isDisconnected } = useAccount()


 const { data, isError, isLoading } = useBalance({
 address: address || undefined,
 token: '0x282D0D15682ACc34de0d4157cD07F35E5af325C7',
 })
const formattedBalance = Number(data?.formatted).toFixed(3);
 
 if (isLoading) return <div>Fetching balance…</div>
 if (isError) return <div>Error fetching balance</div>
 return (
 <div>
 Balance: {formattedBalance} {data?.symbol}
 </div>
 )
}

export default Balance;