import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { ChainId, Token, WETH, Fetcher } from '@uniswap/sdk';
import BigNumber from "bignumber.js";

const TVL_QUERY_INTERVAL = 5000; // query TVL every 5 seconds
const contractAddress: string = "0xE1f683D5C0544DfaD75e9275D889a6B4bA37657d";
const LP_ABI = ['function balanceOf(address owner) view returns (uint256)', 'function decimals() view returns (uint8)'];
const LP_ADDRESS = '0x702786f3D96d078d7256EEF292eCB87A6F862E30'

function ETHMEGATVL() {
  const [tvl, setTVL] = useState<ethers.BigNumber | null>(null);

  useEffect(() => {
    let isMounted = true;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(contractAddress, ['function totalValueLocked() view returns (uint256)'], provider);
    const ethmega = new ethers.Contract(LP_ADDRESS, LP_ABI, provider);

    const queryTVL = async () => {
      try {
        const result = await ethmega.balanceOf(contractAddress);
        if (isMounted) {
          setTVL(result);
        }
      } catch (error) {
        console.error(error);
      }
    };

    queryTVL();
    const intervalId = setInterval(queryTVL, TVL_QUERY_INTERVAL);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div>
      {tvl === null ? (
        <p>Loading TVL...</p>
      ) : (
        <p>${(Number.parseFloat(ethers.utils.formatUnits(tvl, 18)) * (94)).toFixed(2)}</p>
      )}
    </div>
  );
}

export default ETHMEGATVL;
