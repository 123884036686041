import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { ChainId, Token, WETH, Fetcher } from '@uniswap/sdk';
import BigNumber from "bignumber.js";

const TVL_QUERY_INTERVAL = 5000; // query TVL every 5 seconds
const contractAddress: string = "0xfF42d0c6D8846E4DE785134106a1AD9246fBEb7D";
const WETH_ABI = ['function balanceOf(address owner) view returns (uint256)', 'function decimals() view returns (uint8)'];
const WETH_ADDRESS = WETH[ChainId.MAINNET].address;

function ETHTVL() {
  const [tvl, setTVL] = useState<ethers.BigNumber | null>(null);

  useEffect(() => {
    let isMounted = true;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(contractAddress, ['function totalValueLocked() view returns (uint256)'], provider);
    const weth = new ethers.Contract(WETH_ADDRESS, WETH_ABI, provider);

    const queryTVL = async () => {
      try {
        const result = await weth.balanceOf(contractAddress);
        if (isMounted) {
          setTVL(result);
        }
      } catch (error) {
        console.error(error);
      }
    };

    queryTVL();
    const intervalId = setInterval(queryTVL, TVL_QUERY_INTERVAL);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div>
      {tvl === null ? (
        <p>Loading TVL...</p>
      ) : (
        <p>${(Number.parseFloat(ethers.utils.formatUnits(tvl, 18)) * (1850)).toFixed(2)}</p>
      )}
    </div>
  );
}

export default ETHTVL;
