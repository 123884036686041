import { FunctionComponent, useEffect, useMemo, useState } from "react";
import CSS, { Property } from "csstype";
import BigNumber from "bignumber.js";
import useGetStakingInfoMEGA from "../queries/getStakingInfoMEGA";
import { StakeETHForm } from "./StakeETHForm";
import { WithdrawETHForm } from "./WithdrawETHForm";
import { ClaimETH } from "./ClaimETH";
import ETHTVL from "./ETHTVL";




type ETHType = {
  ethDiamondBlack1?: string;
  eTH?: string;

  /** Style props */
  propBackground?: Property.Background;
  dividerWidth?: Property.Width;
  frame12Width?: Property.Width;
};

const ETH: FunctionComponent<ETHType> = ({
  ethDiamondBlack1,
  eTH,
  propBackground,
  dividerWidth,
  frame12Width,
}) => {
  const eTHStyle: CSS.Properties = useMemo(() => {
    return {
      background: propBackground,
    };
  }, [propBackground]);

  const ethDiamondBlack1IconStyle: CSS.Properties = useMemo(() => {
    return {
      width: dividerWidth,
    };
  }, [dividerWidth]);

  const eTH1Style: CSS.Properties = useMemo(() => {
    return {
      width: frame12Width,
    };
  }, [frame12Width]);

  const [ethPrice, setEthPrice] = useState<number | undefined>();

  async function fetchEthPrice() {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
      );
      const data = await response.json();
      const price = data.ethereum.usd;
      setEthPrice(price);
    } catch (error) {
      console.error("Failed to fetch ETH price", error);
    }
  }  

  useEffect(() => {
    fetchEthPrice();
  }, []);
  
  

  const {data, isError, isLoading} = useGetStakingInfoMEGA();
    console.log(data)

    const stakingInfoMEGA = useGetStakingInfoMEGA();
    const ethmegaData = stakingInfoMEGA.data ? stakingInfoMEGA.data.slice(6,9) : [];
    const ethData = stakingInfoMEGA.data ? stakingInfoMEGA.data.slice(3,6) : [];
    const megaData = stakingInfoMEGA.data ? stakingInfoMEGA.data.slice(0,3) : [];


  return (
<div
  className="flex-1 rounded-30xl [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.1),_#e45ca8)] shadow-[4px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-col pt-[35px] px-0 pb-[25px] items-center justify-center text-center text-xl text-white font-satoshi lg:flex-[unset] lg:self-stretch sm:flex-[unset] sm:self-stretch"
  style={{...eTHStyle, padding: '30px 20px'}}
>
      <div className="self-stretch flex flex-col items-center justify-center gap-[18px]">
        <div className="self-stretch flex flex-row items-center justify-center gap-[5px] text-16xl">
          <img
            className="relative w-[27.61px] h-[45px] shrink-0 object-cover"
            alt=""
            src={ethDiamondBlack1}
            style={ethDiamondBlack1IconStyle}
          />
          <b
            className="relative inline-block w-[79px] h-11 shrink-0"
            style={eTH1Style}
          >
            {eTH}
          </b>
        </div>
        <div className="w-[404.84px] flex flex-row items-center justify-between text-left text-sm">
          <div className="rounded-3xs box-border w-[183.92px] h-[58.99px] shrink-0 flex flex-col py-[9.253776550292969px] px-[12.72398567199707px] items-start justify-start border-[1px] border-solid border-white">
            <b className="relative flex items-center w-[30.07px] h-[21.98px] shrink-0">
              TVL
            </b>
            <b className="relative flex items-center w-[92.54px] h-[21.98px] shrink-0 mt-[-3px]">
              <ETHTVL />
            </b>
          </div>
          <div className="rounded-3xs box-border w-[183.92px] h-[58.99px] shrink-0 flex flex-col py-[9.253776550292969px] px-[12.723967552185059px] items-start justify-start border-[1px] border-solid border-white">
            <b className="relative flex items-center w-[32.39px] h-[21.98px] shrink-0">
              APR
            </b>
            <b className="relative flex items-center w-[146.9px] h-[21.98px] shrink-0 mt-[-3px]">
            <p className="m-0">
            {ethData ? BigNumber(Number(ethData[1])).div(10 ** 18).multipliedBy(31536000)
            .dividedBy((6899) * (ethPrice || 0)).multipliedBy(100)
            .toFixed(2) : "N/A"}%
          </p>
            </b>
          </div>
        </div>
        <div className="w-[399px] flex flex-row pt-[22px] px-0 pb-0 box-border justify-between">
          <div className="self-stretch relative font-medium text-left">
            <p className="m-0">MEGA Earned</p>
            <p className="m-0">{ethData ?  BigNumber(Number(ethData[0])).div(10 ** 18).toFixed(3): "N/A"}</p>
          </div>
        <div>
          <ClaimETH />
        </div>
        </div>
        <div className="self-stretch relative box-border h-[3px] shrink-0 border-t-[3px] border-dashed border-white" />
        <div className="w-[396.23px] flex flex-row pt-3.5 px-0 pb-2.5 box-border items-center justify-between text-left">
          <div className="relative font-medium inline-block w-[150.3px] h-[57.84px] shrink-0">
            <p className="m-0">ETH Staked</p>
            <p className="m-0">{ethData ?  BigNumber(Number(ethData[2])).div(10 ** 18).toFixed(2): "N/A"}</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ marginRight: '2px' }}>
          </div>
          <div>
            <StakeETHForm />
          </div>
        </div>

        </div>
        <div className="h-[60px] shrink-0 flex flex-row items-center justify-between gap-[29px]">
          <WithdrawETHForm />
        </div>
      </div>
    </div>
  );
};

export default ETH;