import { FunctionComponent, useEffect } from "react";
import ETH from "./ETH";
import MEGA from "./MEGA";
import ETHMEGA from "./ETHMEGA";
import '../global.css';
import Balance from "./Balance";

const StakingPanel: FunctionComponent = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <div
      className="self-stretch rounded-53xl [background:linear-gradient(rgba(255,_255,_255,_0.35),_rgba(255,_255,_255,_0.35)),_linear-gradient(107.08deg,_rgba(228,_92,_168,_0.35),_rgba(149,_127,_194,_0.35)_47.92%,_rgba(48,_174,_229,_0.35))] shadow-[4px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-col pt-8 px-[61px] pb-[50px] items-center justify-start gap-[55px] [&.animate]:animate-[1s_ease_0s_1_normal_forwards_fade-in] opacity-[0] text-center text-4xl text-white font-satoshi"
      data-animate-on-scroll
    >
      <div className="self-stretch flex flex-col py-[15px] px-0 mb-5 items-center justify-center">
      <div className="self-stretch relative font-black" style={{ fontSize: '36px' }}>MEGA Factory</div>
        <div className="self-stretch relative text-xl sm:text-2xl md:text-3xl lg:text-4xl mt-[-px]">
          Stake tokens to earn MEGA.
          <Balance />
        </div>
      </div>
      <div className="flex justify-center items-center gap-8 sm:gap-5 lg:flex-col lg:items-stretch lg:justify-start">
        <div className="flex-auto">
          <ETH
            ethDiamondBlack1="/eth.svg"
            eTH="ETH"
            propBackground="linear-gradient(180deg, rgba(255, 255, 255, 0.1), #e45ca8)"
            dividerWidth="38px"
            frame12Width="79px"
          />
        </div>
        <div className="flex-auto">
    <ETHMEGA
      ethDiamondBlack1="/LP.svg"
      eTH="ETH-MEGA LP"
      propBackground="linear-gradient(180deg, rgba(255, 255, 255, 0.1), #957FC2)"
      dividerWidth="58px"
      frame12Width="250px" 
    />
        </div>
        <div className="flex-auto">
          <MEGA
            ethDiamondBlack1="/mega.svg"
            eTH="MEGA"
            propBackground="linear-gradient(180deg, rgba(255, 255, 255, 0.1), #30aee5)"
            dividerWidth="44px"
            frame12Width="108px"
          />
        </div>
      </div>
    </div>
  );
};

export default StakingPanel;
