import { FunctionComponent, useEffect, useState } from "react";
import { useMediaQuery } from "@chakra-ui/react";
import Header from "../components/Header";
import StakingPanel from "../components/StakingPanel";
import "../index.css";

const Staking: FunctionComponent = () => {
  const [isLaptop] = useMediaQuery("(max-width: 1600px)");
const [isMobile] = useMediaQuery("(max-width: 1000px)");
const [isMuted, setIsMuted] = useState(true);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  if (isMobile) {
    return (
      <div className="coming-soon">
        <br/ >
          <span>
          Mobile not supported.
          </span>
      </div>
    );
  }

  if (isLaptop) {
    return (
      <div
      className="relative w-full flex flex-col items-center justify-start"
      style={{
        backgroundImage: 'url(/body1@3x.png)',
        backgroundRepeat: 'repeat-y',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100vw',
        height: '100%'
      }}
    >
      <div className="main-content self-stretch flex flex-col py-[87px] px-[189px] items-start justify-start gap-[40px]">
        <Header />
        <StakingPanel />
      </div>
    </div>
    );
  }

  return (
    <div
      className="relative w-full flex flex-col items-center justify-start"
      style={{
        backgroundImage: 'url(/body1@3x.png)',
        backgroundRepeat: 'repeat-y',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100vw',
        height: '100vh'
      }}
    >
      <div className="main-content self-stretch flex flex-col py-[87px] px-[189px] items-start justify-start gap-[40px]">
        <Header />
        <StakingPanel />
      </div>
    </div>
  );
};

export default Staking;
