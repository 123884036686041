import * as React from 'react';
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi';
import { ethers, utils } from "ethers";
import { Button } from "@mui/material";
import useDebounce from './useDebounce';

export function StakeETHForm() {
  const [payableAmount, setPayableAmount] = React.useState('0');

  // Update payableAmount to default to "0" when it is an empty string
  React.useEffect(() => {
    if (payableAmount === '') {
      setPayableAmount('0');
    }
  }, [payableAmount]);
  const debouncedpayableAmount = useDebounce(payableAmount, 500);

  const { config } = usePrepareContractWrite({
    address: '0xfF42d0c6D8846E4DE785134106a1AD9246fBEb7D',
    abi: [
      {
        name: 'stake',
        type: 'function',
        stateMutability: 'payable',
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        outputs: [],
      },
    ],
    functionName: 'stake',
    args: [utils.parseEther(debouncedpayableAmount)],
    enabled: Boolean(debouncedpayableAmount),
    overrides: {
      value: utils.parseEther(debouncedpayableAmount),
      gasLimit: ethers.BigNumber.from(250000),
    },
  });

  const { data, write } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    write?.();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label htmlFor="payableAmount" style={{ marginRight: '10px' }}>
            <h4 style ={{ fontSize: '20px' }}> ETH </h4>
          </label>
          <input
            id="payableAmount"
            onChange={(e) => setPayableAmount(e.target.value)}
            value={payableAmount}
            style={{ color: 'white', width: '75px', height: '30px', textAlign: 'right', marginBottom: '5px', marginRight: '8px', background: 'none', border: '1px solid white', borderRadius: '3px', padding: '3px' }}
          />
        </div>
        <Button
          disabled={!write || isLoading}
          sx={{ width: 124.92630004882812 }}
          variant="outlined"
          color="primary"
          type="submit"
        >
          {isLoading ? 'Staking...' : 'Stake'}
        </Button>
        {isSuccess && (
          <div>
            <h4 style ={{ fontSize: '12px', marginTop: '5px'}}>Successfully staked ETH!</h4>
            <div>
              <h4 style ={{ fontSize: '12px', marginTop: '5px'}}><a href={`https://etherscan.io/tx/${data?.hash}`}>Etherscan</a></h4>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
