import * as React from 'react';
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi';
import { ethers, utils } from "ethers";
import { Button } from "@mui/material";
import useDebounce from './useDebounce';

export function ApproveMEGA() {
  const [payableAmount, setPayableAmount] = React.useState('0');
  const debouncedpayableAmount = useDebounce(payableAmount, 500);

  const { config } = usePrepareContractWrite({
    address: '0xe5162E24DB355C1cc3195972AC3A0dE932CB9E44',
    abi: [
      {
        name: 'stake',
        type: 'function',
        stateMutability: 'payable',
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        outputs: [],
      },
    ],
    functionName: 'stake',
    args: [utils.parseEther(debouncedpayableAmount)],
    enabled: Boolean(debouncedpayableAmount),
    overrides: {
      value: utils.parseEther(debouncedpayableAmount),
    },
  });
  
  const { config: approveConfig } = usePrepareContractWrite({
    address: '0x282D0D15682ACc34de0d4157cD07F35E5af325C7', // the address of the token you want to approve
    abi: [
      {
        name: 'approve',
        type: 'function',
        stateMutability: 'nonpayable',
        inputs: [
          {
            internalType: 'address',
            name: 'spender',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
      },
    ],
    functionName: 'approve',
    args: [
      '0xe5162E24DB355C1cc3195972AC3A0dE932CB9E44', // the address of the contract you want to interact with
      utils.parseEther('999999'),
    ],
    overrides: {
      gasLimit: ethers.BigNumber.from(250000)
    },
  });

  const { data, write } = useContractWrite(config);
  const { data: approveData, write: approveWrite } = useContractWrite(approveConfig);
  

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });
  
  const { isLoading: approveIsLoading, isSuccess: approveIsSuccess } = useWaitForTransaction({
    hash: approveData?.hash,
  });

  const handleApprove = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    approveWrite?.();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    write?.();
    console.log
  };

  return (
      <form onSubmit={handleApprove}>
        <Button
          disabled={!approveWrite || approveIsLoading}
          sx={{ width: 124.92630004882812, marginTop: '35px' }}
          variant="outlined"
          color="primary"
          type="submit"
          style={{ marginBottom: '10px', marginRight: '10px' }}
        >
          {approveIsLoading ? 'Approving...' : 'Approve'}
        </Button>
      </form>
  );
        }  