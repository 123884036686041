import { FunctionComponent } from "react";
import { Button } from "@mui/material";
import { ReactComponent as Logo } from "./megalogo.svg";
import { Connector, useConnect, useDisconnect, useAccount, useBalance } from "wagmi";
  
export const Header: React.FC = () => {
  const { connectAsync, connectors } = useConnect()
  const { disconnect } = useDisconnect();
  const { address, isConnected } = useAccount();

  const { data, isError, isLoading } = useBalance ({
    address: address,
  })

const handleWalletConnect = async (connector: Connector) => {
  const { chain } = await connectAsync({ connector })
  if (chain.unsupported) {
     alert("Unsupported chain")
  }
};

const formattedBalance = Number(data?.formatted).toFixed(3);

const handleDisconnect = () => {
  disconnect();
};

  return (
    <div className="self-stretch flex flex-row items-center justify-between text-left text-6xl text-white font-satoshi relative">
      <div className="max-w-[1531px] w-full mx-auto px-4 flex flex-row items-center justify-between md:flex-col md:gap-1 md:items-start md:justify-center">
        <div className="w-full md:w-auto flex flex-row items-center justify-start gap-1 md:gap-2 md:text-center md:text-2xl lg:text-3xl xl:text-4xl relative z-10">
          <a className="text-[inherit] font-black" href="https://meth.finance">
            Home
          </a>
          <a className="text-[inherit] font-black ml-5" href="https://docs.meth.finance/">
            Tokenomics
          </a>
          <a className="text-[inherit] font-black ml-5" href="https://docs.meth.finance/">
            Docs
          </a>
          <a className="text-[inherit] font-black ml-5" href="https://discord.gg/hfdajKHB">
            Discord
          </a>
          <a className="text-[inherit] font-black ml-5" href="https://twitter.com/methfinance">
            Twitter
          </a>
        </div>
        <div className="absolute left-1/2 transform -translate-x-1/2 flex items-center justify-center z-0">
          <Logo className="w-16 h-16 md:w-32 md:h-32 lg:w-40 lg:h-40 xl:w-48 xl:h-48 object-contain" style={{ width: "125px", height: "auto", maxWidth: "100%", maxHeight: "100%" }} />
        </div>

        {!isConnected && connectors.map((connector) => {
          const { id, name } = connector;
          return <Button
            onClick={() => handleWalletConnect(connector)}
            key={id}
            className="ml-auto z-10"
            sx={{ width: "100%", maxWidth: 288 }}
            variant="outlined"
            color="primary"
            size="large"
          >Connect Wallet
          </Button>;
        })}
        {
          isConnected && 
          <Button 
          onClick={handleDisconnect}
          className="ml-auto z-10"
          sx={{ width: "100%", maxWidth: 400 }}
          variant="outlined"
          color="primary"
          size="large"
          font-family="Satoshi-Light"
          >Disconnect | {formattedBalance}ETH</Button>
        }
      </div>
    </div>
  );
};

export default Header;
