import { WagmiConfig, createClient, configureChains, mainnet} from 'wagmi'
 
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
 
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { FC, ReactNode } from 'react'
import React from 'react'

 
// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, provider, webSocketProvider } = configureChains([mainnet],
    [alchemyProvider({ apiKey: 'FKzQMEDb4chURCS_JTm1DPAAI2hmxdIG' }), publicProvider()],
)
 
// Set up client
const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ 
      chains, 
      options: {
        shimDisconnect: true,
        UNSTABLE_shimOnConnectSelectAccount: true
      }
    }),
  ],
  provider,
  webSocketProvider,
})

interface WagmiProviderProps {
    children: ReactNode
}

export const WagmiProvider: FC<WagmiProviderProps> = ({children}) => {
    return <WagmiConfig client={client}>{children}</WagmiConfig>
};