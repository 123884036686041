import * as React from 'react';
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi';
import { ethers, utils } from "ethers";
import { Button } from "@mui/material";
import useDebounce from './useDebounce';
import "../global.css";

export function WithdrawMEGAForm() {
  const [payableAmount, setPayableAmount] = React.useState('0');
  const debouncedpayableAmount = useDebounce(payableAmount, 500);

  const { config } = usePrepareContractWrite({
    address: '0xe5162E24DB355C1cc3195972AC3A0dE932CB9E44',
    abi: [
      {
        name: 'withdraw',
        type: 'function',
        stateMutability: 'nonpayable',
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        outputs: [],
      },
    ],
    functionName: 'withdraw',
    args: [utils.parseEther(debouncedpayableAmount)],
    enabled: Boolean(debouncedpayableAmount),
    overrides: {
      gasLimit: ethers.BigNumber.from(250000)
    },
  });

  const { data, write } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    write?.();
  };
  
  const contractExit = usePrepareContractWrite({
    address: '0xe5162E24DB355C1cc3195972AC3A0dE932CB9E44',
    abi: [
      {
        name: 'exit',
        type: 'function',
        stateMutability: 'nonpayable',
        inputs: [],
        outputs: [],
      },
    ],
    functionName: 'exit',
    overrides: {
      gasLimit: ethers.BigNumber.from(250000)
    },
  });

  const contractWriteExit = useContractWrite(contractExit.config);

  const contractWriteStatus = useWaitForTransaction({
    hash: contractWriteExit.data?.hash,
  });

  const handleWithdrawAll = () => {
    contractWriteExit.write?.();
  }

  return (
    <div style ={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
<form onSubmit={handleSubmit}>
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <label htmlFor="payableAmount" style={{ marginRight: '10px' }}></label>
      <input
        id="payableAmount"
        onChange={(e) => setPayableAmount(e.target.value)}
        value={payableAmount}
        style={{ color: 'white', width: '60px', height: '35px', textAlign: 'right', marginBottom: '5px', marginRight: '8px', marginLeft: '30px', background: 'none', border: '1px solid white', borderRadius: '3px', padding: '3px' }}
      />
    </div>
    <Button style={{ marginRight: '35px'}}
      disabled={!write || isLoading}
      sx={{ width: 124.92630004882812 }}
      variant="outlined"
      color="primary"
      type="submit"
    >
      {isLoading ? 'Withdrawing...' : 'Withdraw'}
    </Button>
  </div>
  {isSuccess && (
    <div>
      <h4 style ={{ fontSize: '12px', marginTop: '5px'}}>Successfully withdrew MEGA!</h4>
      <div>
        <h4 style ={{ fontSize: '12px', marginTop: '5px'}}><a href={`https://etherscan.io/tx/${data?.hash}`}>Etherscan</a></h4>
      </div>
    </div>
  )}
</form>
      
      <Button style={{ marginLeft: '15px', marginRight: '15px'}}
          disabled={!contractWriteExit.write || contractWriteStatus.isLoading}
          sx={{ width: 165}}
          variant="outlined"
          color="primary"
          onClick={handleWithdrawAll}
        >
          {contractWriteStatus.isLoading ? 'Withdrawing...' : 'Withdraw All'}
      </Button>
    </div>
  );
}